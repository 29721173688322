<template>
<div v-if='loaded'>
  <div class="App fade-in">
        <div class="navbar">
          <img class="hxymoney" style='cursor: pointer;' @click='goTos()' src='/img/hxymoney.png' alt='icon'>
        </div>
        <!-- <div>
          <button class="connect_wallet" @click='connect' >
            {{selectedAccount?(selectedAccount.substr(0,7)+"....."+selectedAccount.substr(-8)):"Approve Metamask"}}</button>
        </div> -->
        <div class="row justify-content-center px-2">
          <section-one></section-one>
          <section-two></section-two>
          <section-three ></section-three>
          <div class="col-12 col-lg-9 mt-5">
          <div class="main-new">
           <div class="summary">
             <p>
               There is a HBSC bonus for hxy business investors which is summarised in the HBSC white paper
           The amount you are eligible to claim is shown below (please
           connect your wallet).
             </p>
            <p>
              The claim period will be open for 
           3 months to allow hxy.business investors to claim their HBSC tokens. Any unclaimed tokens will be reinvested back into the project.
            </p>
            
           </div>
           
<div class="row justify-content-center mt-3" >
          <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-7">
              <claim-widget></claim-widget>
          </div>
          <div class="col-12 mt-5">
            <div class="footer mt-3 py-3">
          <img class='d-block mx-auto' style='width:300px;height:75px;' src="/img/footer.jpg" alt="footer" srcset="">
</div>
          </div>
        </div>
        
        </div>
          </div>

        
        


<div class="col-12">

</div>
        </div>
      
</div>
      
  </div>

</template>

<script>
    const SectionOne = () => import("@/components/SectionOne.vue");
    const SectionTwo = () => import("@/components/SectionTwo.vue");
    const SectionThree = () => import("@/components/SectionThree.vue");
    const ClaimWidget = () => import("@/components/ClaimWidget.vue");

export default {
  components:{SectionOne,SectionTwo,SectionThree,ClaimWidget},
  data() {
    return {
      loaded:false,
      
    }
  },
  async mounted() {
    // await this.timer();
     this.$nextTick(function () {
    this.loaded = true
  })
    
  },
  updated(){
    // console.log("all dom content updated")
    this.loaded = true
  },
  methods:{
    goTo(){
      window.open("https://claimelly.com", "_blank")
    },
    goTos(){
      window.open("https://hbsc-website-test-qelxg.ondigitalocean.app", "_blank")
    },
    
  }
}
</script>

<style scope>

</style>